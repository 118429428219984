@import "../../utilities/mixins.scss";

.main-header {
  padding: 0 0 30px 0;

  .header-top {
    @include header-style;
  }

  .header-links {
    display: flex;
    justify-content: center;

    &.-wardrobe {
      .header-link {
        &.active {
          color: var(--markhams-link-color-active);
        }
      }
    }

    &.-wardrobe-profile {
      .header-link {
        &.active {
          color: var(--markhams-gray);
        }
      }
    }

    .header-link {
      outline: none;
      color: #ffffff;
      font-family: GothamMedium, sans-serif;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0 15px;
      border-right: 2px solid #ffffff;
      text-align: center;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 12px;

      &:last-of-type {
        border: 0;
      }

      &.active {
        font-family: GothamBold, sans-serif;
      }
    }
  }
}
