.wardrobe-container {
  background: var(--markhams-main-color);
  padding: 0 20px;
  width: 100%;
  min-height: 100%;
  padding-bottom: 40px;

  .app-body {
    display: flex;
    flex-direction: column;
  }
}
