@font-face {
  font-family: GothamRegular;
  src: url("../assets/fonts/Gotham-Regular.otf");
}

@font-face {
  font-family: GothamLight;
  src: url("../assets/fonts/Gotham-Light.otf");
}

@font-face {
  font-family: GothamMedium;
  src: url("../assets/fonts/Gotham-Medium.otf");
}

@font-face {
  font-family: GothamBold;
  src: url("../assets/fonts/Gotham-Bold.otf");
}
