.loader-container {
  background: var(--markhams-main-color)
    url("../../assets/images/desktop-background.png") no-repeat top center/cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  @media screen and (max-width: 500px) {
    background: var(--markhams-main-color)
      url("../../assets/images/loading.jpg") no-repeat top center/cover;
  }

  & > div {
    padding: 0 15px;
  }

  .header {
    margin: -100px auto 0 auto;
    text-align: center;

    .heading {
      font-family: GothamBold, sans-serif;
      text-transform: uppercase;
      font-size: 65.3px;
      line-height: 90%;

      & > div {
        font-size: 34.6px;
      }
    }

    span {
      display: block;
    }
  }

  .loader-icon {
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-family: GothamMedium, sans-serif;
    font-size: 13.3px;
    padding-top: 110px;
  }

  .loading-text {
    font-family: GothamBold, sans-serif;
    font-size: 26.6px;
    text-transform: uppercase;
    line-height: 155%;
    text-align: center;
    background: var(--markhams-main-color);
    padding: 10px 50px 30px 30px;
    position: absolute;
    bottom: 0;
    right: 0;

    & > div {
      font-size: 53.3px;
    }
  }
}
