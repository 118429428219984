.submit {
  color: #ffffff;
  border: 0;
  font-family: GothamBold, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:disabled {
    background: #cccccc !important;
    color: #000 !important;
  }

  &.form-submit {
    height: 45px;
    width: 100%;
  }

  &.button-blue {
    border: 1px solid #fff;
    background: transparent;
    font-size: 16px;
    padding: 5px 15px;
  }

  .spinner {
    right: 20px;
    left: unset !important;
  }
}
