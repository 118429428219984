.manican-container {
  width: 100%;
  height: 600px;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;

  .canvas-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    .loader-container {
      position: absolute;
    }

    #create-manican-canvas {
      width: 100%;
    }
  }

  .manican {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .manican-body {
      width: 169px;
      position: absolute;
      bottom: 0;
    }
  }

  .manican-clothes {
    width: 175px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
}
