.estimated-outfit-cost-container {
  .estimated-outfit-cost {
    max-width: 738px;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    display: flex;
    border-bottom: 3px solid #fff;
    padding: 30px 15px 15px 15px;
    align-items: flex-end;

    .label {
      font-family: GothamLight, sans-serif;
      font-size: 12.13px;

      strong {
        display: block;
        font-family: GothamBold, sans-serif;
      }
    }

    .cost {
      color: var(--markhams-link-color-active);
      font-family: GothamBold, sans-serif;
      flex: 1;
      text-align: center;
      font-size: 20px;
      margin-left: -100px;
    }

    .add-to-cart-button {
      border: 0;
      outline: none;
      width: 40px;
      height: 40px;
      background: url('../../assets/icons/icon-cart.svg') no-repeat
        center/contain;
      position: relative;

        span {
          font-family: GothamBold, sans-serif;
          color: var(--markhams-link-color-active);
          position: absolute;
          top: 6px;
          font-size: 12px;
        }
    }
  }

  .loader-container {
    position: fixed;
    top: 0;
    max-width: 800px;
    margin: 0 auto;
    z-index: 1000;
  }
}
