.share-component {
  .icon-close {
    position: absolute;
    right: 10px;
    top: 5px;
    border: 0;
    background: transparent;
    height: 40px;
    width: 40px;
    font-size: 30px;
  }

  .share-buttons {
    width: 30px;
    position: absolute;
    right: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
    grid-gap: 20px;

    .share-button {
      background: none;
      border: 0;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      padding: 0;

      &.navigator-share {
        border-radius: 50%;
        border: 0;
        width: 35px;
        height: 35px;
        display: flex;
        place-items: center;
        justify-content: center;
        background: var(--markhams-link-color-yellow);
      }
    }
  }

  .share-image-container {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid #000;
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 100;
    padding: 0 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .share-button-item {
      padding: 10px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: transparent;

        span {
          background: var(--markhams-blue);
          text-transform: uppercase;
          color: #fff;
          padding: 5px 10px;
          margin-left: 10px;
          font-family: GothamMedium, sans-serif;
          font-size: 11.29px;

          &.facebook {
            background: var(--facebook-color);
          }

          &.whatsapp {
            background: var(--whatsapp-color);
          }

          &.twitter {
            background: var(--twitter-color);
          }
        }
      }
    }

    .image-container {
      max-width: 90%;

      img {
      }
    }
  }
}
