.shared-avatar-screen {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;

  a {
    text-decoration: none;
  }

  .shared-avatar-container {
    padding-top: 20px;
  }
}
