.auth-container,
.edit-details {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;

  & > div {
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    @media screen and (max-width: 800px) {
      background-size: cover !important;
    }
  }

  .form {
    margin: 0 auto;
    width: 400px;
    padding: 60px 0;

    form {
      background: rgba(255, 255, 255, 0.47);
      padding: 25px 10px;
    }
  }

  .avatar-icon {
    margin: 50px auto 30px auto;
    text-align: center;

    img {
      width: 100%;
      max-width: 150px;
    }
  }

  .sign-up-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 15px 10px;

    .logo-outline-container {
      img {
        width: 104px;
        height: auto;
        margin-top: 15px;
      }
    }
  }

  .wardrobe-text {
    text-transform: uppercase;
    color: #ffffff;
    font-family: GothamBold, sans-serif;
    font-size: 28px;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 5px;
  }

  .sign-up-heading {
    text-transform: uppercase;
    color: var(--markhams-main-color);
    font-size: 26.67px;
    text-align: center;
    font-family: GothamBold, sans-serif;
    margin-bottom: 20px;
  }

  .sign-up-using {
    margin-top: 30px;
    margin-bottom: 30px;

    .sign-up-using-text {
      text-align: center;
      font-family: GothamBold, sans-serif;
      font-size: 16px;
      color: var(--markhams-main-color);
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .links {
      display: flex;
      justify-content: space-between;

      & > span {
        opacity: 1 !important;
        width: 50%;
        margin-right: 5px;
      }

      button {
        color: #ffffff;
        border: 0;
        font-family: GothamBold, sans-serif;
        font-size: 12px;
        flex: 1;
        text-transform: uppercase;
        padding: 10px 10px;
        border-radius: 25px;
        background: var(--markhams-main-color);
        width: 100%;

        &:last-of-type {
          margin: 0;
          margin-left: 5px;
        }
      }
    }
  }

  .back-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-transform: uppercase;
    font-family: GothamBold, sans-serif;
    font-size: 14px;
    border: 1px solid #ffffff;
    text-decoration: none;
    padding: 6px 15px;
    background: var(--markhams-main-color);
  }

  .links {
    a {
      text-decoration: none;
      font-family: GothamBold, sans-serif;
      font-size: 12.67px;
      color: var(--markhams-main-color);
      display: block;
      margin-bottom: 10px;
      padding: 5px 0;
      text-align: center;

      strong {
        font-family: GothamBold, sans-serif;
      }
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"] {
    height: 45px;
    padding: 5px 15px 5px 50px;
    border: 0;
    color: #ffffff;
    width: 100%;
    font-family: GothamBold, sans-serif;
    font-size: 10.33px;

    &::placeholder {
      color: darken($color: #ffffff, $amount: 20%);
      text-transform: uppercase;
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    svg {
      color: var(--markhams-pink) !important;
      position: relative !important;
      top: unset !important;
      left: unset !important;
      font-size: 25px !important;
      margin-right: 10px;
    }
  }

  label {
    font-family: GothamLight, sans-serif;
    font-size: 12px;
    cursor: pointer;
    color: var(--markhams-main-color);
  }

  .form-group {
    margin-bottom: 20px;
    width: 100%;
    position: relative;

    .form-field {
      display: flex;
      align-items: center;
      position: relative;
    }

    .icon {
      position: absolute;
      height: 100%;
      width: 33px;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      padding-left: 15px;

      img {
        width: 15px;
        height: auto;
      }
    }

    .loader-icon-container {
      position: absolute;
      right: 15px;
    }

    .error {
      width: 100%;
      background: #fff;
      display: block;
      padding: 3px 15px;
      font-size: 9px;
      font-family: GothamLight, sans-serif;
      color: red;
    }
  }

  .sign-in-screen {
    background: var(--markhams-main-color)
      url("../../assets/images/desktop-background.png") no-repeat top
      center/cover;

    @media screen and (max-width: 500px) {
      background: var(--markhams-main-color)
        url("../../assets/images/login-page.jpg") no-repeat top center/cover;
    }

    input {
      background: var(--markhams-main-color) !important;
    }

    .form-submit {
      background: var(--markhams-main-color);
    }
  }

  .sign-up-screen {
    background: var(--markhams-main-color)
      url("../../assets/images/desktop-background.png") no-repeat top
      center/cover;

    @media screen and (max-width: 500px) {
      background: var(--markhams-main-color)
        url("../../assets/images/sign-up.jpg") no-repeat top center/cover;
    }

    input {
      background: var(--markhams-main-color);

      &[type="text"] {
        text-transform: capitalize;
      }

      &::placeholder {
        text-transform: uppercase;
      }
    }

    .form-submit {
      background: var(--markhams-blue);
    }
  }
}
