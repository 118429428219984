@import "./utilities/style-reset.scss";
@import "./utilities/fonts.scss";
:root {
  --markhams-main-color: #000000;
  --markhams-blue: #1c365c;
  --markhams-gray: #686867;
  --markhams-light-blue: #0080aa;
  --markhams-pink: #e94575;
  --markhams-tabs-heading-color: #a33e19;
  --markhams-link-color: #ffffff;
  --markhams-link-color-yellow: #f7b334;
  --markhams-link-color-active: #f07f1a;
  --markhams-link-color-active-hover: darken(#f07f1a, 10%);
  --semi-transparent-black: rgba(0, 0, 0, 0.3);
  --facebook-color: #4267b2;
  --whatsapp-color: #25d366;
  --twitter-color: #1da1f2;
}

* {
  font-size: 16px;
}

html,
body,
#root,
.app-main-container {
  line-height: unset !important;
  width: 100%;
  height: 100%;
  background: var(--markhams-main-color);

  .app-body {
    max-width: 800px;
    margin: 0 auto;
  }
}

body {
  scroll-behavior: smooth;
}

a,
button,
input {
  outline: none;
  cursor: pointer;
}

a,
button {
  &:hover {
    opacity: 0.9;
  }
}

img {
  width: 100%;
  height: auto;
}

.fade-in {
  animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
