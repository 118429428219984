@import "../../utilities/mixins.scss";

.product-catalogue {
  background: var(--markhams-main-color);
  position: relative;
  min-height: 250px;

  .list-scroll {
    height: 330px;

    @include scroll-bar-style-y;
  }

  .spinner {
    top: 67% !important;
  }

  .catalogue-labels {
    background: var(--markhams-light-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;

    button {
      color: #fff;
      background: transparent;
      padding: 3px 20px;
      border: 0;
      border-right: 2px solid #fff;
      font-family: GothamMedium, sans-serif;
      font-size: 10.67px;
      text-transform: uppercase;
      transition: all 0.3s ease-in;

      &.is-active,
      &:hover {
        color: #2d2b2d;
        font-family: GothamBold, sans-serif;
      }

      &:last-of-type {
        border: 0;
      }
    }
  }

  .catalogue-list-container {
    max-width: 800px;
    margin: 0 auto;
  }

  .catalogue-list {
    max-height: 330px;
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include scroll-bar-style-y;
  }
}
