@import "../profile/styles.scss";
@import "../auth/styles.scss";

.edit-details,
.edit-details {
  @include profile-button-styles;
  max-width: 400px;
  min-width: unset;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

  .submit {
    background: var(--markhams-blue) !important;

    &:disabled {
      background: #ccc !important;
    }
  }

  form {
    width: 100%;
  }

  .label {
    font-family: GothamMedium, sans-serif;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--markhams-link-color-active);
    text-align: center;
    margin-bottom: 10px;
  }

  .input-label {
    color: #fff;
    display: block;
    text-align: center;
    text-transform: capitalize;
    font-family: GothamMedium, sans-serif;
    font-size: 10.67px;
    padding: 5px 0;
  }

  .profile-picture {
    background-color: var(--markhams-light-blue) !important;
    border: 4px solid var(--markhams-link-color-active);
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 0 auto 35px auto;
  }

  .links {
    width: 100%;

    button {
      margin-bottom: 20px;

      &.link-button {
        background: transparent;
        font-size: 10.67px;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .form-field {
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      background: transparent;
      border-bottom: 1px solid #fff;
    }

    input[type="text"] {
      text-transform: capitalize;
    }
  }
}
