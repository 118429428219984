.mainbox {
  background-color: var(--markhams-main-color);
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ._404-msg {
    color: var(--markhams-link-color);
    font-family: GothamRegular, sans-serif;
    font-size: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.2em;
    margin-bottom: 50px;
  }

  .msg {
    text-align: center;
    font-family: GothamLight, sans-serif;
    font-size: 1.6rem;
    width: 75%;
    color: var(--markhams-link-color);
  }

  a {
    text-decoration: none;
    color: var(--markhams-link-color-active);
  }

  a:hover {
    text-decoration: underline;
  }
}
