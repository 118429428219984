.markhams-text {
  text-transform: uppercase;
  color: #ffffff;
  font-family: GothamBold, sans-serif;
  font-size: 44px;
  text-align: center;
  line-height: 95%;
  text-shadow: 7px 6px 3px rgba(0, 0, 0, 0.2);

  div {
    font-size: 22.27px;
  }
}
