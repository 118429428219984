@import "../../utilities/mixins.scss";

.change-background {
  background: var(--markhams-main-color);
  overflow: hidden;

  .change-background-link {
    background: var(--markhams-link-color-active);
    color: var(--markhams-link-color);
    text-decoration: none;
    text-transform: uppercase;
    font-family: GothamBold, sans-serif;
    font-size: 10.67px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 0;

    &.active {
      background: var(--markhams-main-color);
    }
  }

  .background-list {
    display: flex;
    padding: 10px;
    margin: 0 10px;
    @include scroll-bar-style-x;

    .background-item {
      background: white;
      border: 0;
      flex: 1;
      margin: 0 5px;
      position: relative;
      min-width: 180px;
      min-height: 180px;
      max-width: 200px;
      margin-bottom: 20px;

      &:hover {
        .plus-icon {
          height: 100%;
        }
      }

      .background-name {
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--markhams-blue);
        color: var(--markhams-link-color);
        width: 100%;
        font-family: GothamMedium, sans-serif;
        font-size: 10.53px;
        text-transform: uppercase;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .plus-icon {
        position: absolute;
        height: 0;
        width: 100%;
        background: var(--semi-transparent-black);
        top: 0;
        left: 0;
        transition: height, 0.3s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        svg {
          color: var(--markhams-link-color-active);
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
