@mixin profile-button-styles {
  .submit {
    background: var(--markhams-main-color);
    font-family: GothamBold, sans-serif;
    color: #fff;
    text-transform: uppercase;
    display: block;
    border: 0;
    width: 100%;
    padding: 11px 15px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;

    &.edit-avatar {
      input {
        display: none;
      }
    }
  }

  .links {
    padding: 15px 0 0 0;

    a,
    .link-button {
      font-family: GothamBold, sans-serif;
      text-transform: uppercase;
      color: #fff;
      font-size: 10.67px;
      text-decoration: none;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px 15px;

      &.browse-wardrobe {
        background: var(--markhams-blue);
      }
    }
  }
}

.profile-page {
  @include profile-button-styles;
  padding-top: 130px;

  & > div {
    padding: 20px;
  }

  .personal-details {
    color: #fff;
    background: #fff;
  }

  .profile-picture-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .profile-picture {
      background-color: var(--markhams-gray) !important;
      border: 4px solid var(--markhams-blue);
      border-radius: 50%;
      width: 200px;
      height: 200px;
      margin: -130px 35px 0 20px;
      flex-shrink: 0;
    }

    .details {
      flex-direction: column;
      align-items: flex-start;
      color: #fff;
      flex: 1;
      margin-top: -174px;

      @media screen and (max-width: 450px) {
        margin: 0;
        color: #000;
      }

      .name {
        font-family: GothamLight, sans-serif;
        font-size: 16px;
        margin-bottom: 15px;
        text-transform: capitalize;

        strong {
          font-family: GothamBold, sans-serif;
          display: block;
        }
      }
    }

    .submit {
      background: var(--markhams-blue);
      font-size: 10.67px;
      padding: 10px 15px;
      width: auto;
      max-width: 110px;
      margin: 0 auto;
    }
  }

  .details {
    display: flex;
    align-items: flex-start;
    color: #000;
    padding: 20px 0;

    .label {
      font-family: GothamLight, sans-serif;
      font-size: 14.4px;
      padding-right: 20px;
    }

    .details-list {
      font-family: GothamMedium, sans-serif;
      font-size: 10.67px;
      line-height: 18px;
      word-break: break-all;
    }
  }

  .edit-sizes {
    background: #fff;
    color: var(--markhams-main-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid var(--markhams-main-color);

    .current-sizes {
      display: flex;
      text-align: center;
      justify-content: space-around;
      font-family: GothamMedium, sans-serif;
      text-transform: uppercase;
      font-size: 23.32px;
      padding: 30px 40px 40px 40px;
      width: 100%;
      max-width: 550px;

      & > div {
        border: 4px solid var(--markhams-main-color);
        padding: 15px;
        flex-grow: 1;
        max-width: 180px;
        margin: 0 15px;

        &:last-of-type {
          margin: 0;
        }
      }

      strong {
        display: block;
        font-family: GothamBold, sans-serif;
        font-size: 53px;
      }
    }
  }
}
