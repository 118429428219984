.edit-sizes-component {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  left: 0;

  .edit-sizes-inner {
    width: 100%;
    width: 400px;
    margin: 0 auto;
    border: 1px solid var(--markhams-main-color);
    background: #fff;
    padding: 40px;
    box-shadow: -3px 4px 10px 0px rgba(0, 0, 0, 0.43);
    position: relative;

    & * {
      outline: none;
    }

    .icon-close {
      position: absolute;
      right: 10px;
      top: 5px;
      border: 0;
      background: transparent;
      height: 40px;
      width: 40px;
      font-size: 30px;
    }

    .picture {
      height: 180px;
      width: 100%;
      margin: -20px 0 20px 0;
    }

    .slick-arrow {
      width: 30px;
      top: 100px;
      font-size: 0 !important;
      z-index: 1000;

      &:before {
        font-size: 0 !important;
      }

      &.slick-disabled {
        &:before {
          opacity: 0.25 !important;
        }
      }

      &.slick-prev {
        left: 10px;

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-top: 20.27px solid transparent;
          border-bottom: 20.27px solid transparent;
          border-right: 20.27px solid var(--markhams-blue);
          opacity: 1;
        }
      }

      &.slick-next {
        right: 10px;

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-top: 20.27px solid transparent;
          border-bottom: 20.27px solid transparent;
          border-left: 20.27px solid var(--markhams-blue);
          opacity: 1;
        }
      }
    }
  }

  .label {
    font-size: 13.33px;
    font-family: GothamBold, sans-serif;
    color: var(--markhams-main-color);
    text-transform: uppercase;
  }

  .sizes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(53px, 1fr));
    grid-gap: 5px;
    border-top: 2px solid var(--markhams-main-color);
    padding-top: 15px;

    .size-item {
      border: 0;
      background-color: var(--markhams-blue);
      color: #fff;
      font-size: 18.67px;
      font-family: GothamMedium, sans-serif;
      padding: 15px 3px;
      text-align: center;

      &.active {
        background: #fff;
        border: 2px solid var(--markhams-blue);
        color: var(--markhams-blue);
      }
    }
  }
}
