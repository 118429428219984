@import "../../utilities/mixins.scss";

.create-avatar {
  background: var(--markhams-main-color);
  min-height: 100%;
  position: relative;

  a {
    text-decoration: none;
  }

  .header {
    @include header-style;
  }

  .avatar-creator {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .fabric-canvas {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #e9a54f;
    padding: 20px;

    .canvas-container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .user-name {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #000;
        font-family: GothamMedium, sans-serif;
        font-size: 12px;
        text-transform: capitalize;
      }
    }
  }

  .tabs-heading {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    background: #e8e8e8;

    .tab-item {
      border: 0;
      height: 60px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      transition: all 0.3s ease-in;
      position: relative;
      border-right: 1px solid #000;
      border-left: 1px solid #e8e8e8;

      &:last-of-type {
        border: 0;
      }

      @include resize-avatar-assets;

      &.head {
        img {
          height: 140%;
        }
      }

      &.lips {
        img {
          margin-top: -160%;
        }
      }

      &.beard {
        img {
          height: 600%;
          margin-top: -120%;
        }
      }

      &.hair {
        img {
          height: 400%;
          margin-top: 115%;
        }
      }

      &.active {
        background: #e9a54f;
      }

      img {
        width: auto;
        max-height: 90%;
      }

      label {
        position: absolute;
        bottom: 3px;
        font-family: GothamLight, sans-serif;
        font-size: 8px;
      }
    }
  }

  .tabs-content {
    .list-scroll {
      height: 245px;
    }
  }

  .avatar-template-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15px;
    background: #fff;
    padding: 15px;
    height: 240px;
    @include scroll-bar-style-y;

    &::-webkit-scrollbar-track {
      background-image: linear-gradient(
        to left,
        #fff 0% 25%,
        #000 25% 75%,
        #fff 75% 100%
      );
      opacity: 1;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #000;
    }

    .avatar-template-list-item,
    .tab-item {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      padding: 15px;
      background: #e8e8e8;
      transition: all 0.3s ease-in;
      font-family: GothamLight, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      overflow: hidden;

      @include resize-avatar-assets;

      &:hover {
        background: #e9a54f;
      }

      img {
        max-width: 70%;
        height: auto;
      }
    }
  }

  .save-avatar {
    margin: 25px 0;
    background: var(--markhams-blue);
    font-size: 10.67px;
  }

  .links-container {
    padding: 0 15px;
  }
}
