@mixin header-style {
  font-family: GothamBold, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 25px 0;
  font-size: 16px;

  @media screen and (max-width: 440px) {
    text-align: center;
    padding: 30px 0;
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .main-logo {
    width: 34.02px;
    margin-right: 20px;

    @media screen and (max-width: 440px) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
@mixin scroll-bar-style-y {
  /* style scroll bar */
  overflow-y: scroll;
  overflow-y: overlay;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-image: linear-gradient(
      to left,
      var(--markhams-main-color) 0% 25%,
      var(--markhams-blue) 25% 75%,
      var(--markhams-main-color) 75% 100%
    );
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--markhams-blue);
    border-radius: 5px;
  }

  &::-webkit-resizer,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

@mixin scroll-bar-style-x {
  /* style scroll bar */
  overflow-x: scroll;
  overflow-x: overlay;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 25px;
  }

  &::-webkit-scrollbar-track {
    background: #e8e8e8;
  }

  &::-webkit-scrollbar-thumb {
    background: #686867;
    cursor: pointer;
  }

  &::-webkit-resizer,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

@mixin resize-avatar-assets {
  img {
    max-width: unset !important;
    width: unset;
    max-height: unset !important;
  }

  &.eyes,
  &.eyebrows {
    background-size: 150% !important;
  }

  &.nose {
    background-size: 250% !important;
    background-position: center 60% !important;
  }

  &.lips {
    background-size: 250% !important;
    background-position: center 75% !important;
  }

  &.hair {
    background-size: 80% !important;
    background-position: center 0% !important;
  }

  &.beard {
    background-size: 80% !important;
    background-position: center 80% !important;
  }

  &.accessories {
    background-size: 100% !important;
  }

  &.tab-item {
    &.beard {
      background-size: 150% !important;
      background-position: center 90% !important;
    }
  }
}
