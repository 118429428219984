.product-item {
  background: #fff;
  position: relative;
  flex: 1;
  flex-grow: 0;
  margin: 5px;

  &.is-hidden {
    display: none;
  }

  &:hover {
    .product-info {
      height: 100%;
    }
  }

  .product-image-container {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-image {
    height: 100px;
    width: 120px;
    position: relative;
    overflow: hidden;

    .loader {
      .spinner {
        top: 50% !important;
      }
    }
  }

  .product-price {
    width: 100%;
    background: var(--markhams-blue);
    color: #fff;
    font-family: GothamRegular, sans-serif;
    text-align: center;
    font-size: 12px;
    padding: 10px;
  }

  .product-info {
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background: rgba(233, 165, 79, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    transition: height, 0.3s linear;
    overflow: hidden;

    .info-icon {
      font-family: GothamBold, sans-serif;
      font-weight: 900;
      font-size: 40px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      text-decoration: none;
      margin: 0 5px;
      background: #fff;
      overflow: hidden;

      svg {
        transition: all 0.3s;
        box-shadow: -3px 2px 12px 0px rgba(0, 0, 0, 0.51);
      }

      &:hover {
        svg {
          transform: scale(1.05);
        }
      }

      &.product-link {
        svg {
          background: #fff;
          color: #0080aa;
        }
      }

      &.remove-product {
        color: var(--markhams-link-color-active);
      }
    }
  }
}
