.form-feedback {
  background: #ffffff;
  text-align: center;
  font-family: GothamLight, sans-serif !important;
  margin-bottom: 15px !important;

  &.success,
  &.error {
    padding: 10px 20px !important;
  }

  &.success {
    color: #008000;
  }
  &.error {
    color: #ff0000;
  }
}
